import {Injectable, signal} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpEventType
} from '@angular/common/http';
import {Observable, filter} from 'rxjs';
import {map} from "rxjs/operators";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ChunckedLoadingComponent} from "src/app/shared/components/chuncked-loading/chuncked-loading.component";

const ERROR_ID = '#### Chunked Error: ';
const WARNING_ID = '#### Warning:';

@Injectable({
    providedIn: "root",
})
export class ChunckedDialogInterceptor implements HttpInterceptor {

    constructor(
        private dialog: MatDialog,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let lastPartialText: string = '';

        const requestClone = request.clone();
        let dialogRef: MatDialogRef<ChunckedLoadingComponent>;
        let isChuncked = false;

        return next.handle(requestClone)
            .pipe(
                map(event => {

                    if (event.type === HttpEventType.DownloadProgress) {
                        isChuncked = true;

                        if (!dialogRef) {
                            dialogRef = this.dialog.open(ChunckedLoadingComponent);
                        }

                        if (!event['partialText']) {
                            return event ;
                        }

                        if (event['partialText'].includes(ERROR_ID)) {
                            let errorMsg = event['partialText'].split(ERROR_ID)[1]
                            dialogRef.componentInstance.error = true;
                            dialogRef.componentInstance.message = errorMsg;
                            dialogRef.componentInstance.finishCounter();
                            throw new Error(errorMsg)
                        }

                        let lastMsg = event['partialText'].replace(lastPartialText, '');
                        let objsChunckedMsgs = lastMsg.split('}\n').filter(msg => msg).map(msg => msg + '}');

                        for (let msg of objsChunckedMsgs) {
                            let obj: {fullProgress: number, progress: number, message: string};
                            try {
                                obj = JSON.parse(msg);
                                dialogRef.componentInstance.message = obj.message;
                                dialogRef.componentInstance.progress = (obj.progress * 100) / obj.fullProgress;
                                lastPartialText = event['partialText'];
                                dialogRef.componentInstance.history = event['partialText'];
                                dialogRef.componentInstance.historyList.push({
                                    message: `${obj.message}<br>`,
                                    error: obj.message.includes(WARNING_ID),
                                    time: dialogRef.componentInstance.timerValue()
                                });
                            } catch (e) {
                                dialogRef.componentInstance.message = msg;
                            }
                        }

                    } else {
                        dialogRef?.componentInstance?.finishCounter();
                    }
                    return event;
                }),
                filter((event) => {
                    if (isChuncked) {
                        if (event.type === HttpEventType.Response) {
                            dialogRef?.componentInstance?.finishCounter();
                            if (!dialogRef?.componentInstance?.error) {
                                dialogRef?.close();
                            }
                        }
                        return event.type === HttpEventType.Response;
                    } else {
                        return true;
                    }
                }),
            );
    }
}
